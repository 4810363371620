import { Fragment, useContext, useEffect } from "react"
import logo_entel from "assets/images/logo_entel.svg"
import background_image_ship from "assets/images/background-image-ship.svg"
import shade_backg_ship from "assets/images/shade-backg-ship.svg"
import Loading from "overrides/theme/entel/base/components/Commons/Loading/Loading"
import { loadingFormStyle } from "overrides/theme/entel/base/pages/LoadScreen/LoadScreen.style"
import Helmet from "components/Commons/Helmet/Helmet"
import { useAuth0 } from "@auth0/auth0-react"
import { useHistory } from "react-router-dom"
import { AuthContext } from "context/context/AuthContext"
import { EShippinggroupStatus } from "enums/shippinggroupEmun"

const LoadingPage = () => {
  const {
    dispatch,
    state: { error, loading },
  } = useContext(AuthContext)
  const { user, isAuthenticated, isLoading } = useAuth0()
  const history = useHistory()

  useEffect(() => {
    if (Boolean(isAuthenticated) && Boolean(user) && !Boolean(isLoading)) {
      dispatch({ type: "[auth] Set menu user", payload: [{ statusOrder: EShippinggroupStatus.all }] })

      dispatch({
        type: "[auth] Login success",
        payload: {
          id: user?.nickname || "",
          email: user?.email || "",
          groups: [{ id: "guest", mainGroup: "nn" }],
          mainGroup: {
            id: "guest",
            name: "guest",
          },
          name: user?.name || "",
          role: user?.omnixRoles && user?.omnixRoles[0]?.role,
          token: user?.authentication?.token,
          currentSources: ["guest"],
          custom: {
            ...user?.userProfile,
            jobPosition: user?.omnixRoles  && user?.omnixRoles[0]?.jobPosition,
          },
        },
      })
    }
    if (!Boolean(isAuthenticated) && !Boolean(isLoading)) {
      history.push("/")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, user, isLoading])

  return (
    <Fragment>
      <Helmet />
      <Loading defaultOpened={loading} />
      <div className="cont-loading">
        <div className="cont-loading--sub__left">
          <div className="sub--left__body">
            <img src={logo_entel} alt="logo" className="client-logo" />
            <div className="info-loading">
              <h4>Espere...</h4>
            </div>
            <div className="cont-form-loading">
              {error && (
                <div className="error-message">
                  <p>{error}</p>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="cont-loading--sub__right">
          <img src={shade_backg_ship} alt="X" className="shade-bg" />
          <div className="sub--right__body">
            <img src={background_image_ship} alt="X" className="main-bg-image" />
            <p className="bg-title">Llega más rápido y haz feliz a tu cliente</p>
            <p className="bg-description">
              Desde nuestra plataforma gestiona pedidos, envíos, el inventario y almacenes.
            </p>
          </div>
        </div>
      </div>
      <style jsx>{loadingFormStyle}</style>
    </Fragment>
  )
}

export default LoadingPage
