import product_image_default from "assets/images/entel/product-image-default.svg"
import { useTranslation } from "react-i18next"
import usePickingItems from "overrides/theme/entel/base/hooks/v4/usePickingItems"
import { useParams } from "react-router-dom"
import ProductOderDetail from "overrides/theme/entel/base/components/OrderDetail/ProductOderDetail/ProductOrderDetail"
import { useEffect } from "react"
// import { IProcessItem } from "interfaces/IItemsOrder"

const Item = (props: any) => {
  const { shippingtype }: any = useParams()
  const { t } = useTranslation()
  const { item, status, series } = props
  const _item = [item]
  const is_seriable_item = item?.type !== "Accesorios"

  const { currentItem, dispachPickingTransition, pickingTransition, seriesTyped, is_picking_state } = usePickingItems({
    itemsOrder: _item,
    status,
    shippingType: shippingtype,
  })

  useEffect(() => {
    if (Boolean(pickingTransition?.processItems?.length)) {
      let _series = pickingTransition?.processItems[0] || {}
      series.dispatch([...series.state, _series])
    }
    // eslint-disable-next-line
  }, [pickingTransition])

  useEffect(() => {
    if (Boolean(!is_seriable_item) && Boolean(!series?.state?.some((_ser: any) => _ser?.sku === item?.sku))) {
      series.dispatch([
        ...series.state,
        {
          sku: item?.sku,
          quantity: item?.quantity,
          serie: [],
        },
      ])
    }
    // eslint-disable-next-line
  }, [item, is_seriable_item])

  const handleClickProcessProduct = (item: any) => {
    if (item) {
      if (pickingTransition.isEditing) {
        const nextItemIndex = item?.findIndex(
          (item: any) => !pickingTransition.processItems.some(({ sku }) => sku === item.sku),
        )
        dispachPickingTransition({
          type: "EDIT_ITEM_SERIES",
          payload: {
            itemEdit: item,
            nextItemIndex: nextItemIndex || -1,
          },
        })

        return
      }
      dispachPickingTransition({ type: "ADD_PROCESS_ITEM", payload: item })
    }
  }

  const template = (
    <article className="order__products__list__item">
      <div className="order__products__list__item__wrapper">
        <div className="order__products__list__item__description">
          <div className="order__products__list__item__image">
            {item?.image ? (
              <img src={item?.image} alt={item?.name} />
            ) : (
              <img src={product_image_default} alt={item?.name} />
            )}
          </div>
          <div className="order__products__list__item__details">
            <h4>{item?.name}</h4>
            <div>
              <p>{t("SKU")}: </p>
              <p>
                <strong>{item?.sku}</strong>
              </p>
            </div>
            <div>
              <p>{t("Categorías")}: </p>
              <p>
                <strong>{item?.type}</strong>
              </p>
            </div>
            <div>
              <p>{t("Cantidad")}: </p>
              <p>
                <strong>{item?.quantity}</strong>
              </p>
            </div>
            <div>
              <p>{t("Volume")}: </p>
              <p>
                <strong>{item?.volume}</strong>
              </p>
            </div>
          </div>
        </div>
        {is_picking_state ? (
          <div className="order__products__list__item__cont_series">
            {seriesTyped ? (
              <div className="order__products__list__item__details">
                <h4>Series ingresadas</h4>
                {seriesTyped?.map((barcode: string, i: number) => (
                  <div key={i}>
                    <p>
                      {i + 1}. {barcode}
                    </p>
                  </div>
                ))}
              </div>
            ) : is_seriable_item ? (
              <ProductOderDetail itemOrder={currentItem} onClicProcessProduct={handleClickProcessProduct} />
            ) : (
              <p>Este item no requiere ingresar series.</p>
            )}
          </div>
        ) : null}
      </div>
    </article>
  )

  return template
}

export default Item
