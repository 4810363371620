import { useParams } from "react-router-dom"
import useUserPermissions from "hooks/UseUserPermissions"
import { ConfigGroupOrderType } from "types/OrderType"
import { EShippinggroupAction, EShippinggroupStatus } from "enums/shippinggroupEmun"
import { ResourcePermissionsEnum } from "enums/ResourcePermissionsEnum"
import { useTranslation } from "react-i18next"

const useConditionalsGroupOrder = () => {
  const { type, shippingtype } = useParams<{ type: string, shippingtype: string }>()

  const { t } = useTranslation()

  const configGroupOrder: Array<ConfigGroupOrderType> = [
    {
      status: EShippinggroupStatus.package,
      action: EShippinggroupAction.packed,
      labelAction: t("LABEL_PACKED_GROUP"),
      permission: ResourcePermissionsEnum.shippingGroup,
      shippingType: "sp_hd"
    },
    {
      status: EShippinggroupStatus.PACKED_AND_READY_FOR_PICKUP,
      action: EShippinggroupAction.deliver_to_courier,
      permission: ResourcePermissionsEnum.shippingGroup,
      labelAction: "Entregado a courier",
      shippingType: "hd"
    },
  ]

  const statusGroup = configGroupOrder?.find(({ status, shippingType }: any) => 
    status.toLowerCase() === type.toLowerCase() && shippingType === shippingtype
  )

  const { permission } = useUserPermissions({
    resources: statusGroup?.permission ? [statusGroup?.permission] : [],
  })

  return {
    canSelectMultiple: !!statusGroup && permission[statusGroup.permission],
    statusGroup,
    typeStatus: type,
  }
}

export default useConditionalsGroupOrder
