const getCustomValue = (value: string) => {
  if (window.location.href.includes("privilege")) {
    switch (value) {
      case "transit_to_source":
        return "IN_TRANSIT"
      case "pickup_in_my_source":
        return "READY_FOR_PICKUP"
      default:
        return ""
    }
  }
  return null
}

export enum EShippinggroupStatus {
  confirmation = "AWAITING_STORE_CONFIRMATION" as any,
  package = "PENDING_PACKAGE" as any,
  delivery = "AWAITING_DELIVERY" as any,
  transit = "IN_TRANSIT" as any,
  in_source_preparation = "IN_SOURCE_PREPARATION" as any,
  return_in_review = "RETURN_IN_REVIEW" as any,
  transit_source = "IN_TRANSIT_SOURCE" as any,
  pickup = "READY_FOR_PICKUP" as any,
  pickup_source = "READY_FOR_PICKUP_SOURCE" as any,
  closed = "CLOSED" as any,
  delivered = "DELIVERED" as any,
  canceled = "CANCELED" as any,
  rejected = "REJECTED" as any,
  in_return = "IN_RETURN" as any,
  returned = "RETURNED" as any,
  all = "all" as any,
  // TODO: To call the services correctly, custom paths are read independently of the original states and counter states, this could require refactoring.
  transit_to_source = getCustomValue("transit_to_source") as any,
  pickup_in_my_source = getCustomValue("pickup_in_my_source") as any,
  in_transit_for_reception = "IN_TRANSIT_FOR_RECEPTION" as any,
  in_transit_for_reception_opl = "IN_TRANSIT_FOR_RECEPTION_OPL" as any,
  // --- Entel
  ASSIGNED = "ASSIGNED" as any,
  IN_PREPARATION = "IN_PREPARATION" as any,
  PICKING_ID_GENERATED = "PICKING_ID_GENERATED" as any,
  PICKING_IN_PROGRESS = "PICKING_IN_PROGRESS" as any,
  VALIDATE_PICKING = "VALIDATE_PICKING" as any,
  PICKING_CONFIRMED = "PICKING_CONFIRMED" as any,
  PICKING_COMPLETED_PENDING_PACKAGE = "PICKING_COMPLETED_PENDING_PACKAGE" as any,
  PACKED_AND_READY_FOR_PICKUP = "PACKED_AND_READY_FOR_PICKUP" as any,
  DELIVERED_TO_COURIER = "DELIVERED_TO_COURIER" as any,
  IN_TRANSIT = "IN_TRANSIT" as any,
  ORDER_RECEIVED = "ORDER_RECEIVED" as any,
  DELIVERED = "DELIVERED" as any,
  DELIVERED_IN_STORE = "DELIVERED_IN_STORE" as any,
  READY_FOR_PICKUP = "READY_FOR_PICKUP" as any,
  PICKUP_AVAILABLE = "PICKUP_AVAILABLE" as any,
  SUCCESSFUL_PAYMENT = "SUCCESSFUL_PAYMENT" as any,
  PICKED_UP = "PICKED_UP" as any,
  CANCELED = "CANCELED" as any,
  DELIVERED_IN_SOURCE = "DELIVERED_IN_SOURCE" as any,
  ORDER_RETURNED = "ORDER_RETURNED" as any,
}

export enum EShippinggroupAction {
  accept = "accept",
  // packed = "packed",
  // shipped = "shipped",
  // delivered = "delivered",
  prepared = "prepared",
  delivered_reject = "delivered_reject",
  // picked_up = "picked_up",
  reject = "reject",
  // cancel = "cancel",
  cancel_decrease = "cancel_decrease",
  cancelLogistic = "cancel_logistic",
  customer_reject = "customer_reject",
  delivered_return = "delivered_return",
  // --- Entel
  select = "select",
  generate_picking_id = "generate_picking_id",
  pick = "pick",
  validate_picking = "validate_picking",
  picking_confirm = "picking_confirm",
  picking_complete = "picking_complete",
  packed = "packed",
  deliver_to_courier = "deliver_to_courier",
  shipped = "shipped",
  order_received = "order_received",
  delivered = "delivered",
  delivered_in_store = "delivered_in_store",
  ready_for_pickup = "ready_for_pickup",
  pickup_available = "pickup_available",
  successful_payment = "successful_payment",
  picked_up = "picked_up",
  delivered_in_source = "delivered_in_source",
  order_returned = "order_returned",
  cancel = "cancel",
}
