import { useTranslation } from "react-i18next"
import { formatDate } from "helpers/FormatDateHelper"

const OrderDetailTraces = (props: any) => {
  const { t } = useTranslation()
  const { data } = props

  const template = data?._sg.trace && (
    <article className="order__traces">
      <h2>{t("Traces")}</h2>
      <section>
        {data?._sg.trace?.map((item: any, index: number) => (
          <article className="order__traces__container" key={index}>
            <h4 className="order__traces__title">
              <span>{item?.name}</span>
              <span>{formatDate(item?.date)}</span>
            </h4>
            <div className="order__traces__body">
              <h5>{t("Descripción")}</h5>
              <p className="order__traces__body__text order__traces__body__text--description">{item?.description}</p>

              <h5>{t("Información adicional")}</h5>
              <div>
                {Object.entries(item?.custom).map((_item: any, _index: number) => (
                  <p className="order__traces__body__text order__traces__body__text--custom-key" key={_index}>
                    <span>{t(_item[0])}:</span> <span>{_item[1]}</span>
                  </p>
                ))}
              </div>
            </div>
          </article>
        ))}
      </section>
    </article>
  )

  return template || <></>
}

export default OrderDetailTraces
