import { useContext } from "react"
import { authorizerService } from "services/Middleware"
import Logger from "classes/Logger"
import { AuthContext } from "context/context/AuthContext"
import { TransactionCodeEnum } from "enums/TransactionCodeEnum"
import { Error_GNERAL, ERROR_LOGIN } from "helpers/constHelper"
import JsonEngine from "classes/JsonEngine"
import { EShippinggroupStatus } from "enums/shippinggroupEmun"
// import { useCancelToken } from "hooks"
import { Env, MultiClient } from "classes"
import configEnv from "config/configEnv"

const useAuthorizer = () => {
  const {
    dispatch,
    state: { error, loading },
  } = useContext(AuthContext)
  // const { newCancelToken } = useCancelToken()
  let [, _env] = MultiClient.get_user_env(null, Env.clients, Env.env, Env.defaults)

  const loginUser = async () => {
    try {
      dispatch({ type: "[auth] Login start" })

      // Temporal handler
      const cred_dev = {
        audience: configEnv.auth0Audience_dev,
        grant_type: "client_credentials",
        client_id: configEnv.auth0ClientId_dev,
        client_secret: configEnv.auth0ClientSecret_dev,
      }

      const cred_uat = {
        audience: configEnv.auth0Audience_uat,
        grant_type: "client_credentials",
        client_id: configEnv.auth0ClientId_uat,
        client_secret: configEnv.auth0ClientSecret_uat,
      }

      const cred_prod = {
        audience: configEnv.auth0Audience_prod,
        grant_type: "client_credentials",
        client_id: configEnv.auth0ClientId_prod,
        client_secret: configEnv.auth0ClientSecret_prod,
      }

      const credentials = _env === "prod" ? cred_prod : _env === "uat" ? cred_uat : cred_dev

      const responseLogin = await authorizerService(credentials)

      const {
        data: { access_token },
      } = responseLogin

      dispatch({ type: "[auth] Set menu user", payload: [{ statusOrder: EShippinggroupStatus.all }] })

      dispatch({
        type: "[auth] Login success",
        payload: {
          id: "guest",
          email: "guest",
          groups: [{ id: "guest", mainGroup: "nn" }],
          mainGroup: {
            id: "guest",
            name: "guest",
          },
          name: "Invitado",
          role: "guest",
          token: access_token,
          currentSources: ["guest"],
        },
      })
    } catch (error: any) {
      Logger.error(error)

      if (error.response?.status === TransactionCodeEnum.unauthorized)
        dispatch({ type: "[auth] Login error", payload: ERROR_LOGIN })
      else dispatch({ type: "[auth] Login error", payload: Error_GNERAL })
    }
  }

  //Send to the context the log out action and clean the user information in the local storage
  const logout = () => {
    dispatch({ type: "[auth] Logout" })
    JsonEngine.clear()
  }

  return {
    loading,
    error,
    loginUser,
    logout,
  }
}

export default useAuthorizer
