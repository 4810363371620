import { useEffect, useState } from "react"
import { IoGrid, IoList } from "react-icons/io5"
import { useTranslation } from "react-i18next"
import SelectBox from "components/Commons/SelectBox/SelectBox"
import SummaryOptions from "overrides/theme/entel/base/components/Summary/SummaryOptions"
// import { useEnv } from "hooks"
import Loading from "overrides/theme/entel/base/components/Commons/Loading/Loading"
// import { useOrderGrouped } from "overrides/theme/entel/base/hooks"

// import summary_status from "overrides/theme/entel/base/helpers/summary_status"
import usePostMiddlewareOsrm from "overrides/theme/entel/base/hooks/v4/usePostMiddlewareOsrm"
import SummaryPopupChanges from "overrides/theme/entel/base/components/Summary/SummaryPopupChanges"

const DashboardContent = (props: any) => {
  let { data, class_modifier, title, select_options, state_machine_states } = props
  const { t } = useTranslation()
  const [view, set_view] = useState("block")
  const [data_status, set_data_status]: any = useState(null)
  const [loading, set_loading] = useState(false)
  const [select_value, select_dispatch] = useState(() => (!!select_options ? select_options[0]["id"] : null))
  const { post_middleware_query_action } = usePostMiddlewareOsrm()

  const event_set_view = (type_view: any) => {
    if (view !== type_view) set_view(type_view)
  }

  const event_set_select = (value: any) => select_dispatch(value)

  const fetch_data = async () => {
    set_loading(true)
    const response = await post_middleware_query_action("search/shipping_groups/group", {
      groupBy: {
        "_omnixStateMachine.currentState.state.name": {
          size: 50,
        },
      },
    })
    let statusses = response?.data?.message["_omnixStateMachine.currentState.state.name"] || []

    statusses = statusses.map((status: any) => {
      const [key] = Object.keys(status)
      const [value] = Object.values(status)
      const _val = {
        status: key,
        title: key,
        optionCount: value,
        path: `/order/hd/${key}`,
      }
      return _val
    })
    let sgs_counter = state_machine_states.map((state: any) => {
      const _count = statusses.find((_st: any) => _st?.status === state?.label) || {}
      return { ...state, ..._count }
    })

    set_data_status(
      sgs_counter.map((sg: any) => {
        if (Boolean(!sg?.optionCount)) {
          return { ...sg, status: sg?.label, title: sg?.label, optionCount: 0, path: `/order/hd/${sg?.label}` }
        }
        return sg
      }),
    )
    set_loading(false)
  }

  useEffect(() => {
    if (select_value !== null && Boolean(state_machine_states?.length)) {
      fetch_data()
    }
    //eslint-disable-next-line
  }, [select_value, state_machine_states])

  const template = (
    <>
      {data_status && (
        <SummaryPopupChanges
          data_status={{ state: data_status, dispatch: set_data_status }}
          call_service_counter={fetch_data}
        />
      )}
      {loading && <Loading defaultOpened={true} />}
      <section
        className={`dashboard__content__children dashboard__content__children--${class_modifier} dashboard__content__children--${view}`}
      >
        <article className="dashboard__content__children__header">
          <div className="dashboard__content__children__header__title">
            <h3>{t(title)}</h3>
          </div>
          <div className="dashboard__content__children__header__options">
            <div
              className="dashboard__content__children__header__options__item dashboard__content__children__header__options__item--block"
              onClick={() => event_set_view("block")}
            >
              <p>{t("Vista Resumen")}</p> <IoGrid />
            </div>
            <div
              className="dashboard__content__children__header__options__item dashboard__content__children__header__options__item--list"
              onClick={() => event_set_view("list")}
            >
              <p>{t("Vista Lista")}</p> <IoList />
            </div>
          </div>
        </article>
        <article className="dashboard__content__children__filters">
          {!!select_options && (
            <>
              <p>{t("Filtrar por")}</p>
              <div className="dashboard__content__children__filters__select">
                <SelectBox options={select_options} onchange={event_set_select} defaultValue={select_options[0]} />
              </div>
            </>
          )}
        </article>
        <article className="dashboard__content__children__items">
          {!!data_status && <SummaryOptions summaryOptions={data_status} />}
          {!!data && <SummaryOptions summaryOptions={data} />}
        </article>
      </section>
    </>
  )

  return template
}

export default DashboardContent
