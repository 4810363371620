import { Fragment } from "react"
import CardOrdeItem from "overrides/theme/entel/base/components/Core/Order/CardOrder/CardOrdeItem"
import { orderListStyle } from "overrides/theme/entel/base/components/Core/Order/styles/OrderList.style"

const OrderList = ({
  ordersGroup,
  showStatus,
  currentDisplayList,
  handleClickCheckOrder,
  canSelectMultiple,
  conditionChecked,
  ordersSGChecked,
  statusSG,
}: any) => {
  return (
    <Fragment>
      {ordersGroup && (
        <>
          {Object.keys(ordersGroup).map((key, index) => (
            <div className="order-list__content-group" key={key}>
              <div className="order-list__group-item">
                <p>{key}</p>
              </div>
              {currentDisplayList === "list" && index === 0 && (
                <div className="order-list__lv__header-titles-container">
                  <div
                    className="order-list__lv__content-titles"
                    style={{ paddingLeft: canSelectMultiple ? "100px" : "90px" }}
                  >
                    <p className="order-list__lv__item-title"></p>
                    <p className="order-list__lv__item-title">PORTABILIDAD</p>
                    <p className="order-list__lv__item-title">ID EOC</p>
                    <p className="order-list__lv__item-title">TIPO DE ENTREGA</p>
                    <p className="order-list__lv__item-title">CANAL</p>
                    <p className="order-list__lv__item-title">COURIER</p>
                    <p className="order-list__lv__item-title">NOMBRE CLIENTE</p>
                    <p className="order-list__lv__item-title">CREACION</p>
                    <p className="order-list__lv__item-title">SLOT DELIVERY</p>
                    <p className="order-list__lv__item-title">COMUNA DESTINO</p>
                    <p className="order-list__lv__item-title">FECHA EST. ENTREGA</p>
                    <p className="order-list__lv__item-title">BODEGA/TIENDA</p>
                  </div>
                </div>
              )}
              <div
                className={
                  currentDisplayList === "grid"
                    ? "order-list__cont-list order-list__gv__cont-list"
                    : "order-list__cont-list"
                }
              >
                {ordersGroup[key].map((order: any) => (
                  <CardOrdeItem
                    key={`${order.orderId}-${order?.id}`}
                    {...order}
                    canSelect={canSelectMultiple && (!conditionChecked || conditionChecked(order))}
                    handleClickSelectOrder={handleClickCheckOrder}
                    isCheckedOrder={ordersSGChecked?.includes(order.id)}
                    statusSG={statusSG}
                    showStatus={showStatus}
                    currentDisplayList={currentDisplayList}
                  />
                ))}
              </div>
            </div>
          ))}
        </>
      )}
      <style jsx>{orderListStyle}</style>
    </Fragment>
  )
}

export default OrderList
