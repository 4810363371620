export const mainLayoutStyle = `
  .root {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .content {
    padding: 1rem;
    width: 100%;
  }
  @media screen and (min-width: 1280px) {
    .container {
      flex-direction: row;
      margin: 0 auto;
      max-width: 1440px;
      position: relative;
    }
    .content {
      padding: 1rem;
      width: 100%;
    }
  }
`