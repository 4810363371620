export const orderScreenStyle = `
  .order-screen__container {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .order-screen__container__header__options {
    display: flex;
    flex-direction: row;
    gap: 1em;
  }
  .order-screen__section-filters {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 1em;
    padding: 0.5em 2em;
    margin-top: 1em;
    border-bottom: 0.5px solid #dddde9;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    background: rgba(255, 255, 255, 0.5);
    box-shadow: 1px -1px 5px 0px rgba(0 0 0 / 10%);
  }
  .order-screen__section-filters_check_enabled {
    flex-direction: column-reverse;
  }
  .order-screen__content-dispatch-action {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 3em;
  }
  .order-screen__check-container {
    display: flex;
    flex-direction: column;
    position: relative;
    cursor: pointer;
    padding-left: 2em;
  }
  .order-screen__check-container input {
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .order-screen__check-checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 1.2rem;
    width: 1.2rem;
    background-color: #FFFFFF;
    border: solid 1.5px #002eff;
    border-radius: 0.2rem;
  }
  .order-screen__check-container:hover input ~ .order-screen__check-checkmark {
    background-color: #002eff;
  }
  .order-screen__check-container input:checked ~ .order-screen__check-checkmark {
    background-color: #002eff;
  }
  .order-screen__check-checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  .order-screen__check-container input:checked ~ .order-screen__check-checkmark:after {
    display: block;
  }
  .order-screen__check-container .order-screen__check-checkmark:after {
    left: 6px;
    top: 2px;
    width: 5px;
    height: 12px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }
  .order-screen__button-dispatch-action {
    padding: 0.5em 1em;
    border-radius: 1.5rem;
    font-family: "Poppins";
    font-size: 1rem;
    color: #FFFFFF;
    background: linear-gradient(90deg, #002eff -2.99%, #002eff 16.38%, #002eff 108.07%);
  }
  .order-screen__button-dispatch-action:hover {
    background: linear-gradient(90deg, #002eff -2.99%, #002eff 16.38%, #002eff 108.07%);
  }
  .order-screen__button-dispatch-action:disabled {
    background: #E0E0E0;
  }
  .order-screen__order-list-container {
    width: 100%;
    background: rgba(255, 255, 255, 0.5);
    box-shadow: 1px 4px 4px rgb(0 0 0 / 10%);
    padding: 1rem;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    overflow-x: scroll;
  }
  .order-screen__order-list-container::-webkit-scrollbar {
    width: 15;
    height: 15;
    background: #F6F6F4;
  }
  .order-screen__order-list-container::-webkit-scrollbar-thumb {
    border-radius: 1rem;
    background: #A9A8B7;
  }
  .order-screen__paginate-container {
    width: auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
  .order-screen__content__header__options > :not(:first-child) {
    display: none; 
  }
  .order-screen__content__header__options__item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    gap: 0 0.5em;
    color: #716f87;
  }
  .order-screen__content__header__options_bordered-select {
    width: fit-content;
    border: 1px solid #716f87;
    padding: 0 0.2rem;
    border-radius: 1rem;
    font-size: 0.8rem;
  }
  @media screen and (min-width: 768px) {
    .order-screen__section-filters {
      flex-direction: row;
      justify-content: flex-end;
    }
    .order-screen__section-filters_check_enabled {
      flex-direction: row;
      justify-content: space-between;
    }
  }
  @media screen and (min-width: 1280px) {
    .order-screen__content__header__options > :not(:first-child) {
      display: flex; 
    }
    .order-screen__content__header__options {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 0 2em;
    }
  }
`