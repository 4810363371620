import { memo } from "react"
import { useParams } from "react-router-dom"
import { IconArrowRigth, IconIoStorefront, IconStar } from "theme/common/Icon.styled"
import { ICardOrderItem } from "interfaces/IOrder"
import { useTranslation } from "react-i18next"
import { cardOrderItemStyle } from "overrides/theme/entel/base/components/Core/Order/CardOrder/CardOrderItem.styled"
import translatesObj from "overrides/theme/entel/base/translates/translation.json"
import { formatDate, formatTime } from "helpers/FormatDateHelper"
import ReactTooltip from "react-tooltip"

const CardOrderItem = memo(
  ({
    id,
    orderId,
    channel,
    salesChannelId,
    source,
    target,
    orderCreationDate,
    logisticPlan,
    shippingType,
    slotDelivery,
    orderType,
    deliveryType,
    estimatedDeliveryDate,
    _omnixStateMachine,
    canSelect = false,
    handleClickSelectOrder,
    isCheckedOrder,
    showStatus,
    currentDisplayList = "grid",
  }: ICardOrderItem) => {
    const { t } = useTranslation()
    const { shippingtype: shippingtype_param }: any = useParams()
    let paramType = _omnixStateMachine?.currentState?.state?.id as string
    paramType = paramType?.toLocaleLowerCase()
    let shipping_type = shippingtype_param || shippingType?.toLocaleLowerCase()
    const infoSgToPrint = { id, orderId, source, target }
    const statusses: any = translatesObj

    return (
      <>
        <div
          className={
            currentDisplayList === "grid"
              ? "card-order-item__container"
              : "card-order-item__container card-order-item__lv__container"
          }
        >
          {canSelect && (
            <div
              className={
                currentDisplayList === "grid"
                  ? "card-order-item__content-order-checked"
                  : "card-order-item__content-order-checked card-order-item__lv__content-order-checked"
              }
            >
              <label className="card-order-item__check-container">
                <input
                  type="checkbox"
                  id={id}
                  name={id}
                  onChange={(ev) => handleClickSelectOrder(ev, infoSgToPrint)}
                  checked={isCheckedOrder}
                />
                <span className="card-order-item__check-checkmark"></span>
              </label>
            </div>
          )}
          <a
            className={
              currentDisplayList === "grid"
                ? "card-order-item__content-order-info"
                : "card-order-item__content-order-info card-order-item__lv__content-order-info"
            }
            href={`/order/${shipping_type}/${id}/${orderId}`}
          >
            <div
              className={
                currentDisplayList === "grid"
                  ? "card-order-item__content-item-order"
                  : "card-order-item__content-item-order card-order-item__lv__content-item-order"
              }
            >
              <div
                className={
                  currentDisplayList === "grid"
                    ? "card-order-item__header-card"
                    : "card-order-item__header-card card-order-item__lv__header-card"
                }
              >
                <div className="card-order-item__header-left">
                  <IconIoStorefront className="card-order-item__header-icon" color="#002eff" />
                  <p className="card-order-item__header-title">{t(shippingType)}</p>
                </div>
                <IconArrowRigth className="card-order-item__header-arrow" color="#002eff" />
              </div>
              <div
                className={
                  currentDisplayList === "grid"
                    ? "card-order-item__body-card"
                    : "card-order-item__body-card card-order-item__lv__body-card"
                }
              >
                <div
                  className={
                    currentDisplayList === "grid"
                      ? "card-order-item__body-card-item--titles"
                      : "card-order-item__body-card-item--titles card-order-item__lv__body-card-item--titles"
                  }
                >
                  <p className="card-order-item__body-card-item-title">PORTABILIDAD</p>
                  <p className="card-order-item__body-card-item-title">ID EOC</p>
                  <p className="card-order-item__body-card-item-title">TIPO DE ENTREGA</p>
                  <p className="card-order-item__body-card-item-title">CANAL</p>
                  <p className="card-order-item__body-card-item-title">COURIER</p>
                  <p className="card-order-item__body-card-item-title">NOMBRE CLIENTE</p>
                  <p className="card-order-item__body-card-item-title">CREACION</p>
                  <p className="card-order-item__body-card-item-title">SLOT DELIVERY</p>
                  <p className="card-order-item__body-card-item-title">COMUNA DESTINO</p>
                  <p className="card-order-item__body-card-item-title uppercase">FECHA ESTIMADA ENTREGA</p>
                  <p className="card-order-item__body-card-item-title">BODEGA/TIENDA</p>
                  {showStatus && <p className="card-order-item__body-card-item-title">ESTADO</p>}
                </div>
                <div
                  className={
                    currentDisplayList === "grid"
                      ? "card-order-item__body-card-item--values"
                      : "card-order-item__body-card-item--values card-order-item__lv__body-card-item--values"
                  }
                >
                  <p className="card-order-item__body-card-item-value">
                    {orderType === "Portabilidad" ? <IconStar style={{fontSize: "1rem"}} /> : "No"}
                  </p>
                  <p className="card-order-item__body-card-item-value">{salesChannelId || "-"}</p>
                  <p className="card-order-item__body-card-item-value">{deliveryType || "-"}</p>
                  <p className="card-order-item__body-card-item-value">{channel || "-"}</p>
                  <p className="card-order-item__body-card-item-value">
                    {logisticPlan.route ? logisticPlan.route[0]?.courier?.id : "-"}
                  </p>
                  <p className="card-order-item__body-card-item-value">{target?.name}</p>
                  <p className="card-order-item__body-card-item-value">
                    {formatDate(orderCreationDate || "")}, {formatTime(new Date(orderCreationDate) || "")}
                  </p>
                  <p className="card-order-item__body-card-item-value">
                    {slotDelivery
                      ? t(
                          `${slotDelivery?.slotType} / ${formatTime(slotDelivery?.initialHour)} - ${formatTime(
                            slotDelivery?.finalHour,
                          )}`,
                        )
                      : "-"}
                  </p>
                  <p className="card-order-item__body-card-item-value">
                    {logisticPlan.route ? logisticPlan.route[0]?.target?.data?.criteria3 : "-"}
                  </p>
                  <p className="card-order-item__body-card-item-value">{formatDate(estimatedDeliveryDate || "")}</p>
                  <p className="card-order-item__body-card-item-value">
                    <ReactTooltip />
                    <span data-tip={source?.name || ""}>{source?.id ? source?.id : "-"}</span>
                  </p>
                  {showStatus && (
                    <p className="card-order-item__body-card-item-value">
                      {statusses[paramType?.toUpperCase()]?.title || paramType?.toUpperCase()}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </a>
        </div>
        <style jsx>{cardOrderItemStyle}</style>
      </>
    )
  },
)

export default CardOrderItem
