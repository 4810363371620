export const orderListStyle = `
  .order-list__content-group {
    width: auto;
    overflow: visible;
  }
  .order-list__group-item {
    width: 10rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1rem;
    background: #E0E0E0;
    padding: 3px;
    margin: 0.5em 0;
  }
  .order-list__group-item p {
    font-family: 'Montserrat';
    font-size: 0.9rem;
    color: #716F87;
  }
  .order-list__cont-list {
    width: inherit;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.5em;
    margin-right: 1rem;
  }
  .order-list__gv__cont-list {
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    gap: 1em;
    display: grid;
  }
  .order-list__lv__header-titles-container {
    display: none;
  }
  @media screen and (min-width: 768px) {
    .order-list__gv__cont-list {
      grid-template-columns: repeat(auto-fill, minmax(390px, 1fr));
    }
  }
  @media screen and (min-width: 1280px) {
    .order-list__lv__header-titles-container {
      width: inherit;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-end;
    }
    .order-list__lv__header-titles-container .order-list__lv__item-title {
      width: 160px;
    }
    .order-list__lv__content-titles {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      padding-bottom: 0.5em;
      padding-left: 90px;
      font-family: 'Montserrat';
      font-size: 0.75rem;
      color: #716F87;
    }
    .order-list__lv__item-title {
      padding: 0 0.5rem;
    }
  }
`