import { useState, useEffect, useContext } from "react"
import { GlobalContext } from "context/context/GlobalContext"
import { OderGroupingType } from "types/OrderType"
import Logger from "classes/Logger"
import { TransactionCodeEnum } from "enums/TransactionCodeEnum"
import useConditionalsGroupOrder from "overrides/theme/entel/base/components/Core/Order/hooks/UseConditionalsGroupOrder"
import useUpdateMultipleSgs from "overrides/theme/entel/base/hooks/v4/useUpdateMultipleSgs"

interface ICheckList {
  data: OderGroupingType
  request: any
}

const useChekedOrderList = ({data: ordersGroup, request}: ICheckList) => {
  const [ordersSGChecked, setOrdersSGChecked] = useState<Array<string>>([])
  const [ordersSGCheckedToPrint, setOrdersSGCheckedToPrint] = useState<Array<any>>([])
  const [selectAll, setSelectAll] = useState(false)
  const [disabledUpdateSG, setDisabledUpdateSG] = useState(true)
  const [loadingUpdateSG, setLoadingUpdateSG] = useState(false)
  const { notification, errorHander } = useContext(GlobalContext)
  const { post_update_multiple_sgs_action } = useUpdateMultipleSgs()

  const { canSelectMultiple, statusGroup, typeStatus } = useConditionalsGroupOrder()

  useEffect(() => {
    const anySelected = ordersSGChecked.length === 0
    setDisabledUpdateSG(anySelected)
  }, [ordersSGChecked])

  const checkAllOrders = (checked: boolean) => {
    setSelectAll(!selectAll)

    if (!checked) {
      setOrdersSGChecked([])
      setOrdersSGCheckedToPrint([])
      return
    }
    if (ordersGroup) {
      const orders = Object.keys(ordersGroup)
      let ordersId: Array<string> = orders.flatMap((key) => ordersGroup[key].map(({ id }) => id))
      let letOrdersToPrint: Array<any> = orders.flatMap((key) =>
        ordersGroup[key].map(({ id, orderId, source, target }) => ({ id, orderId, source, target })),
      )
      setOrdersSGChecked(ordersId)
      setOrdersSGCheckedToPrint(letOrdersToPrint)
    }
  }

  const updateShippingGroupAction = async () => {
    if (!statusGroup) return
    try {
      setLoadingUpdateSG(true)

      const resultUpdateOrders = await post_update_multiple_sgs_action({
        action: statusGroup.action,
        entities: ordersSGChecked?.map((sg: string) => ({ id: sg })),
      })

      setLoadingUpdateSG(false)

      if (resultUpdateOrders?.data?.code === TransactionCodeEnum.ok) {
        notification?.dispatch({
          type: "ADD_NOTIFICATION",
          payload: {
            message: "se actualizo correctamente los SG’s",
            title: "Actualización SG’s",
            type: "success",
          },
        })

        setTimeout(() => request(), 1200)
      }
    } catch (error: any) {
      setLoadingUpdateSG(false)
      if (error.response?.status === 500) {
        notification?.dispatch({
          type: "ADD_NOTIFICATION",
          payload: {
            message: "verifique el estado de los SG’s",
            title: "Algo salió mal",
            type: "danger",
          },
        })
      } else {
        Logger.error(error)
        errorHander?.dispatch({ hasError: true, code: error.response?.status })
      }
    }
  }

  // const alertExample = () => {
  //   setLoadingUpdateSG(true)
  //   setTimeout(() => {
  //     notification?.dispatch({
  //       type: "ADD_NOTIFICATION",
  //       payload: {
  //         message: "se actualizo correctamente los SG’s",
  //         title: "Actualización SG’s",
  //         type: "success",
  //       },
  //     })
  //     setLoadingUpdateSG(false)
  //   }, 2000)
  // }

  return {
    canSelectMultiple,
    ordersSGChecked,
    selectAll,
    setOrdersSGChecked,
    checkAllOrders,
    statusSG: typeStatus,
    disabledUpdateSG,
    loadingUpdateSG,
    updateShippingGroupAction,
    conditionChecked: statusGroup?.predicate,
    labelGroupAction: statusGroup?.labelAction,
    ordersSGCheckedToPrint,
    setOrdersSGCheckedToPrint,
  }
}

export default useChekedOrderList
