import { useState, useReducer, useEffect } from "react"
import { IContext } from "interfaces/IContext"
import { GlobalContext } from "context/context/GlobalContext"
import { NotificationReducer } from "context/reducers/NotificationReducer"
import { IErrorHandler } from "interfaces/IErrorHandler"

/**
 * GlobalProvider this is the global context of services
 * @param {children} type IContext 
 * @return {Element} of type GlobalContext.Provider
 */
const GlobalProvider = ({ children }: IContext) => {
  const [showUserProfileHeader, setShowUserProfileHeader] = useState(false)
  const [notifcations, setNotifications] = useReducer(NotificationReducer, [])
  const [error, setError] = useState<IErrorHandler>({ hasError: false })
  const [displayRouteName, setDisplayRouteName] = useState("")
  const [boxSearch, setBoxSearch] = useState({ option: "", value: "" })
  const [state_machine_states, set_state_machine_status] = useState([])

  useEffect(() => {
    const local_state_machine = localStorage.getItem("STATE_MACHINE")
    if (!local_state_machine) {
      localStorage.removeItem("STATE_MACHINE")
      return
    }

    set_state_machine_status(JSON.parse(local_state_machine) || [])
  }, [])

  const dataProvider = {
    // It is to know if it is in the path of the user information in the header
    userProfileHeader: {
      state: showUserProfileHeader,
      dispatch: setShowUserProfileHeader,
    },
    // this is Notifications for the user
    notification: {
      state: notifcations,
      dispatch: setNotifications,
    },
    //this is to handle global bugs
    errorHander: {
      state: error,
      dispatch: setError,
    },
    displayHeaderRouteName: {
      state: displayRouteName,
      dispatch: (payload: string) => setDisplayRouteName(payload),
    },
    userSearch: {
      state: boxSearch,
      dispatch: setBoxSearch,
    },
    state_machine_states: {
      state: state_machine_states,
      dispatch: set_state_machine_status,
    },
  }

  return <GlobalContext.Provider value={dataProvider}>{children}</GlobalContext.Provider>
}

export default GlobalProvider
