import { post_middleware_query, post_middleware_query_items } from "services/Middleware"
import useCancelToken from "hooks/UseCancelToken"
import Swal from "sweetalert2"
import useAuthorizer from "./useAuthorizer"

interface IMiddlewareQuery {
  skipRecords: number
  maxRecords: number
  sort?: string
  entityType: string
  filter?: any
  project?: Array<string>
}

interface IMiddlewareQueryItems {
  filter: Array<string>
  page?: number
  excludeZeros?: boolean
}

const usePostMiddlewareQuery = () => {
  const { newCancelToken, isCancel } = useCancelToken()
  const { logout } = useAuthorizer()

  const post_middleware_query_action = async (body: IMiddlewareQuery) => {
    try {
      return await post_middleware_query(body, newCancelToken())
    } catch (error: any) {
      if (isCancel(error)) return
      Swal.fire({
        title: "Error",
        text: error?.response?.data?.message?.error,
        icon: "error",
      })
      logout()
    }
  }

  const post_middleware_query_items_action = async (body: IMiddlewareQueryItems, channel: string) => {
    try {
      return await post_middleware_query_items(body, channel, newCancelToken())
    } catch (error: any) {
      if (isCancel(error)) return
      Swal.fire({
        title: "Error",
        text: error?.response?.data?.message?.error,
        icon: "error",
      })
      logout()
    }
  }

  return {
    post_middleware_query_action,
    post_middleware_query_items_action,
  }
}

export default usePostMiddlewareQuery
