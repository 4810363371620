// import Modal from "components/Commons/Modal/Modal"
import { SetStateAction, useContext, useEffect, useState } from "react"
// import { SummaryPopupChangesStyle } from "overrides/theme/entel/base/components/Summary/SummaryPopupChanges/SummaryPopupChanges.style"
// import logo_entel from "assets/images/logo_entel.svg"
import usePostMiddlewareOsrm from "overrides/theme/entel/base/hooks/v4/usePostMiddlewareOsrm"
import { GlobalContext } from "context/context"
// import translatesObj from "overrides/theme/entel/base/translates/translation.json"

interface ISummaryPopupChanges {
  data_status: {
    state: any
    dispatch: SetStateAction<any>
  }
  call_service_counter: any
}

const SummaryPopupChanges = ({ data_status, call_service_counter }: ISummaryPopupChanges) => {
  const { notification } = useContext(GlobalContext)
  // const [changed_status, set_changed_status] = useState<Array<string>>(["DELIVER_TO_COURIER", "IN_TRANSIT"])
  const [reload, set_reload] = useState<boolean>(false)
  const { post_middleware_query_action } = usePostMiddlewareOsrm()
  // const refModal = useRef<React.ElementRef<typeof Modal>>(null)
  // const statusses: any = translatesObj

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>
    if (!reload) {
      timeout = setTimeout(() => set_reload(true), 60000)
    }
    return () => {
      clearTimeout(timeout)
    }
  }, [reload])

  useEffect(() => {
    reload && call_verify_data()
    // eslint-disable-next-line
  }, [reload])

  const call_verify_data = async () => {
    const response = await post_middleware_query_action("search/shipping_groups/group", {
      groupBy: {
        "_omnixStateMachine.currentState.state.name": {
          size: 50,
        },
      },
    })
    let statusses = response?.data?.message["_omnixStateMachine.currentState.state.name"] || []

    statusses = statusses.map((status: any) => {
      const [key] = Object.keys(status)
      const [value] = Object.values(status)
      const _val = {
        status: key,
        title: key,
        optionCount: value,
      }
      return _val
    })

    let status_with_changes: any = []

    data_status?.state?.map((sts: any) => {
      const status_to_compare = statusses.find(
        (newsts: any) => newsts?.status?.toUpperCase() === sts?.id?.toUpperCase(),
      )
      if (status_to_compare) {
        if (sts?.optionCount !== status_to_compare?.optionCount) {
          status_with_changes.push(sts?.id?.toUpperCase())
        }
      }
      return true
    })
    if (status_with_changes?.length) {
      // set_changed_status(status_with_changes)
      // refModal.current?.open()
      call_service_counter()
      notification?.dispatch({
        type: "ADD_NOTIFICATION",
        payload: {
          message: "Se han detectado cambios en los estados y se han actualizado.",
          title: "Cambios de estado",
          type: "success",
        },
      })
    }
    // --
    set_reload(false)
  }

  // const handle_update_counter_button = () => {
  //   refModal.current?.close()
  //   call_service_counter()
  // }

  return <></>
  // return (
  //   <Modal ref={refModal} defaultOpened={false}>
  //     <div className="summary_popup_changes__main">
  //       <div className="summary_popup_changes__cont_tittle">
  //         <h2>Cambios de estado</h2>
  //       </div>
  //       <div className="summary_popup_changes__cont_logo">
  //         <img src={logo_entel} alt="logo" className="header__client-logo" />
  //       </div>
  //       <div className="summary_popup_changes__content_status">
  //         <p>Se han detectado cambios en los siguientes estados:</p>
  //         <ul>
  //           <li>
  //             {changed_status.map((sts: string) => (
  //               <p>
  //                 <strong>- {statusses[sts]?.title || sts}</strong>
  //               </p>
  //             ))}
  //           </li>
  //         </ul>
  //       </div>
  //       <button className="summary_popup_changes__button_reload" onClick={handle_update_counter_button}>
  //         Actualizar
  //       </button>
  //     </div>
  //     <style jsx>{SummaryPopupChangesStyle}</style>
  //   </Modal>
  // )
}

export default SummaryPopupChanges
