import AppRouter from "routes/AppRouter"
import AuthProvider from "context/providers/AuthProvider"
import GlobalStyled from "theme/base/GlobalStyled"
import { ThemeProvider } from "styled-components"
import { theme } from "theme/ThemeUI"
import { Auth0Provider } from "@auth0/auth0-react"
import { MultiClient, Env } from "classes"
import configEnv from "config/configEnv"

const AppContainer = () => {
  let [_client, _env] = MultiClient.get_user_env(null, Env.clients, Env.env, Env.defaults)

  const cred =
    _env === "prod"
      ? { clientId: configEnv.auth0ClientId_prod, domain: configEnv.auth0Domain_prod }
      : _env === "uat"
      ? { clientId: configEnv.auth0ClientId_uat, domain: configEnv.auth0Domain_uat }
      : { clientId: configEnv.auth0ClientId_dev, domain: configEnv.auth0Domain_dev }

  const SimpleProv = () => (
    <AuthProvider>
      <ThemeProvider theme={theme.default}>
        <GlobalStyled />
        <AppRouter />
      </ThemeProvider>
    </AuthProvider>
  )

  const Auth0Prov = () => (
    <Auth0Provider
      clientId={cred.clientId || ""}
      domain={cred.domain || ""}
      authorizationParams={{
        redirect_uri: `${window.location.origin}/callback`,
      }}
    >
      <SimpleProv />
    </Auth0Provider>
  )

  const RootProv: any = _client === "entel" ? Auth0Prov : SimpleProv

  return <RootProv />
}

export default AppContainer
