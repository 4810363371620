import { GlobalContext } from "context/context/GlobalContext"
import { useContext } from "react"
import { PiSpinnerGapBold } from "react-icons/pi"

interface ILabelGenerateDocuments {
  show_loader: boolean
  children: any
  currentStatus: string
}

const LabelGenerateDocuments = ({ currentStatus, show_loader, children }: ILabelGenerateDocuments) => {
  const { state_machine_states } = useContext(GlobalContext)
  
  const PICKING_COMPLETED_PENDING_PACKAGE_INDEX = state_machine_states?.state?.map((st: any) => st?.label).indexOf("PICKING_COMPLETED_PENDING_PACKAGE")
  const CURRENT_STATUS_INDEX = state_machine_states?.state?.map((st: any) => st?.label).indexOf(currentStatus)

  if (CURRENT_STATUS_INDEX < PICKING_COMPLETED_PENDING_PACKAGE_INDEX) return <p>No disponible</p>
  if (!show_loader) return children
  return (
    <p className="flex flex-row gap-2">
      Esperando recepción del documento...
      <PiSpinnerGapBold className="order__main__detail__spinner_data font-bold" />
    </p>
  )
}

export default LabelGenerateDocuments
