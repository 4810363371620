import { useTranslation } from "react-i18next"
import Item from "overrides/theme/entel/base/pages/DetailOrderScreens/Item"

const OrderDetailProductListGrid = (props: any) => {
    const { t } = useTranslation()
    const { data, series } = props
    const items = data?._items || []

    const currentStatus = data?._sg?._omnixStateMachine?.currentState?.state?.name

    const template = (
        <article className="order__products">
            <h2>{t("Productos")}</h2>
            <section className="order__products__list">
                {items?.map((slide: any, index: number) => (
                    <Item key={index} item={slide} status={currentStatus} series={series} />
                ))}
            </section>
        </article>
    )

    return template
}

export default OrderDetailProductListGrid

