export const buttonIconPopUpStyle = `
  .header__cont-button {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0.8em 2em;
    border-top: 1px solid #EBEBF4;
    cursor: pointer;
  }
  .header__cont-button:hover {
    opacity: 0.7;
  }
  .header__cont-button img {
    width: 2rem;
  }
  .header__cont-button p {
    font-size: 1rem;
    font-family: "Montserrat";
    color: #716F87;
    padding-left: 1rem;
  }
`

export const headerStyle = `
  .header__cont-header {
    width: 100%;
    height: 4.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 1em 2em;
    background-color: #FFFFFF;
    box-shadow: 0px 5px 5px -5px rgba(0,0,0,0.75);
  }
  .header__cont-logo-and-title {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  .header__cont-notification-and-user {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
  .header__cont-logo-and-title a img {
    height: 2rem;
  }
  .header__cont-logo-and-title h3 {
    display: none;
  }
  .header__dropdown-user {
    width: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    cursor: default;
    padding-left: 0.5em;
  }
  .header__dropdown-user .header__profile-icon {
    cursor: pointer;
    height: 2.5rem;
  }
  .header__user-info {
    padding: 0.5em;
    text-align: right;
    color: #23203F;
    text-transform: capitalize;
  }
  .header__user-info-in-button {
    display: none;
  }
  .header__user-info .header__name {
    font-family: "Poppins";
    font-size: 0.9rem;
  }
  .header__user-info .header__role {
    font-family: "Montserrat";
    font-size: 0.6rem;
  }
  .header__dropdown-menu {
    display: flex;
    position: absolute;
    width: 70%;
    right: 0.5em;
    top: 12rem;
    transform: translate3d(0, -50%, 0);
    box-shadow: 1px 4px 8px rgba(0, 0, 0, 0.5);
    background: none;
    border-radius: 1em;
    z-index: 100;
    transition: all 0.5s;
    opacity: 0;
    visibility: hidden;
  }
  .header__dropdown-menu:before {
    content: "";
    width: 20px;
    height: 20px;
    transform: rotate(-45deg);
    background: #FFFFFF;
    position: absolute;
    box-shadow: 1px 4px 8px rgba(0, 0, 0, 0.5);
    z-index: -1;
    top: -10px;
    left: calc(90% - 15px);
  }
  .header__dropdown-menu .header__dropdown-menu__body {
    width: 100%;
    background: #FFFFFF;
    padding: 0.5em 0;
    border-radius: 1em;
  }
  .header__dropdown-menu__secondary_text {
    font-family: "Montserrat";
    font-size: 0.8rem;
  }
  .header__dropdown-menu__secondary_text_cont {
    width: 100%;
    padding: 0 1rem 1rem 1rem;
  }
  .header__cont-user-info__popup {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 1em 1em;
  }
  .header__cont-user-info__popup .header__profile-icon-popup {
    width: 4rem;
  }
  .header__cont-user-info__popup .header__user-info {
    text-align: left;
  }
  .header__cont-buttons {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .header__box--show {
    transition: all 0.5s;
    opacity: 1;
    visibility: visible;
  }
  @media screen and (min-width: 768px) {
    .header__cont-logo-and-title h3 {
      display: block;
      font-family: 'Poppins';
      font-weight: 600;
      font-size: 1.2rem;
      color: #23203F;
      align-self: center;
      padding-left: 3em;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .header__dropdown-menu {
      width: 20rem;
    }
    .header__box--show {
      transition: all 0.5s;
      opacity: 1;
      visibility: visible;
    }
  }
  @media screen and (min-width: 1280px) {
    .header__cont-header {
      padding: 0.5rem 1rem;
      padding-right: 1.5rem;
    }
    .header__dropdown-menu {
      top: 12rem;
    }
    .header__user-info-in-button {
      display: block;
    }
    .header__box--show {
      transition: all 0.5s;
      opacity: 1;
      visibility: visible;
    }
  }
` 