let maxRecords = Number(process.env.REACT_APP_MAX_RECORDS)
maxRecords = isNaN(maxRecords) ? 10 : maxRecords

const configEnv = {
  urlOder: process.env.REACT_APP_API_URL_ORDER,
  maxRecords,
  urlItems: process.env.REACT_APP_API_URL_ITEMS,
  urlSources: process.env.REACT_APP_API_URL_SOURCES_OIM,
  urlSourcesAllOIM: process.env.REACT_APP_API_URL_SOURCES_OIM_ALL,
  urlUser: process.env.REACT_APP_API_URL_USER,
  retriesReques: process.env.REACT_APP_RETRIES_REQUEST,
  urlValidateSeries: process.env.REACT_APP_API_URL_SERIES,
  urlValidateSimcard: process.env.REACT_APP_API_URL_SIMCARD,
  urlUpdateDelivery: process.env.REACT_APP_API_URL_COURIER_UPDATE_DELIVERY,
  urlConfirmDelivery: process.env.REACT_APP_API_URL_COURIER_CONFIRM_DELIVERY,
  urlGenerateSgDocuments: process.env.REACT_APP_API_URL_GENERATE_DOCS,
  urlSearch: process.env.REACT_APP_API_URL_SEARCH,
  urlNotificationPickupCode: process.env.REACT_APP_API_URL_NOTIFICATION_PICKUPCODE,
  urlDeliveryCodeGenerate: process.env.REACT_APP_API_URL_DELIVERY_CODE_GENERATE,
  urlDeliveryCodeValidate: process.env.REACT_APP_API_URL_DELIVERY_CODE_VALIDATE,
  urlManifestGenerate: process.env.REACT_APP_API_URL_MANIFEST_GENERATE,
  urlManifestSearch: process.env.REACT_APP_API_URL_MANIFEST_SEARCH,
  urlTicket: process.env.REACT_APP_API_URL_TICKET,
  // Auth0 vars
  // dev
  auth0ClientId_dev: process.env.REACT_APP_AUTH0_CLIENT_ID_DEV,
  auth0ClientSecret_dev: process.env.REACT_APP_AUTH0_CLIENT_SECRET_DEV,
  auth0Audience_dev: process.env.REACT_APP_AUTH0_AUDIENCE_DEV,
  auth0Domain_dev: process.env.REACT_APP_AUTH0_DOMAIN_DEV,
  // uat
  auth0ClientId_uat: process.env.REACT_APP_AUTH0_CLIENT_ID_UAT,
  auth0ClientSecret_uat: process.env.REACT_APP_AUTH0_CLIENT_SECRET_UAT,
  auth0Audience_uat: process.env.REACT_APP_AUTH0_AUDIENCE_UAT,
  auth0Domain_uat: process.env.REACT_APP_AUTH0_DOMAIN_UAT,
  // prod
  auth0ClientId_prod: process.env.REACT_APP_AUTH0_CLIENT_ID_PROD,
  auth0ClientSecret_prod: process.env.REACT_APP_AUTH0_CLIENT_SECRET_PROD,
  auth0Audience_prod: process.env.REACT_APP_AUTH0_AUDIENCE_PROD,
  auth0Domain_prod: process.env.REACT_APP_AUTH0_DOMAIN_PROD
}

export default configEnv
